<template>
  <div>
    <ul class="navigation navigation-main">
      <template v-for="(item, index) in items">
        <vertical-nav-menu-header
          :key="index"
          v-if="item.header"
          :item="item"
        ></vertical-nav-menu-header>
        <vertical-nav-menu-group
          :key="index"
          v-if="item.children"
          :item="item"
        ></vertical-nav-menu-group>
        <vertical-nav-menu-link
          :key="index"
          v-if="!item.children && !item.header"
          :item="item"
        ></vertical-nav-menu-link>
      </template>
    </ul>
  </div>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";


export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  computed: {
    items() {
      return [
        {
          header: "Pages",
        },
        {
          title: "Projects",
          route: { name: "projects" },
          icon: "ServerIcon",
        },
        {
          title: "Tempaltes",
          route: { name: "templates-view" },
          icon: "ArchiveIcon",
        },
        {
          title: "Project Setting",
          icon: "SettingsIcon",
          children: [
            {
              title: "General",
              route: {
                name: "apps-users-edit",
                params: {
                  projectId: this.$store.state.Project.project.id,
                },
              },
            },
            {
              title: "Hosting",
              icon: "Share2Icon",
              route: {
                name: "project-setting-hosting",
                params: {
                  projectId: this.$store.state.Project.project.id,
                },
              },
            },
          ],
        },

        {
          title: "Editor",
          icon: "BoxIcon",
          children: [
            {
              title: "Editors List",
              icon: "ListIcon",
              route: {
                name: "apps-users-view",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Editor",
              icon: "EditIcon",
              route: {
                name: "apps-editor-edit",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Source Files",
              icon: "FileIcon",
              route: {
                name: "source-files",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Background/Environment",
              route: { 
                name: "background-environment",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Editor Frequency",
              route: {
                name: "editor-frequency",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Set AR/VR",
              icon: "ApertureIcon",
              route: {
                name: "editor-ar-vr",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Interface",
              icon:"GridIcon",
              route: {
                name: "interface",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                },
              },
            },
            {
              title: "Effects",
              icon: "FeatherIcon",
              route: {
                name: "effects",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                }
              },
            },
            {
              title: "Camera movements",
              icon: "VideoIcon",
              route: {
                name: "movements",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                }
              },
            },
            {
              title: "Code",
              icon: "CodeIcon",
              route: {
                name: "code",
                params: {
                  projectId: this.$store.state.Project.project.id,
                  editorId: this.$store.state.Editor.editor.id,
                }
              },
            },
          ],
        },
        {
          title: "Analytics",
          icon: "PieChartIcon",
          children: [
            {
              title: "Statistics",
              route: {
                name: "analytics-statistics",
                params: {
                  projectId: this.$store.state.Project.project.id,
                },
              },
            },
            {
              title: "Simulation",
              route: {
                name: "analytics-simulation",
                params: {
                  projectId: this.$store.state.Project.project.id,
                },
              },
            },
          ],
        },
        {
          title: "Team",
          route: {
            name: "team-list",
            params: {
              projectId: this.$store.state.Project.project.id,
            },
          },
          icon: "UsersIcon",
        },
        {
          title: "Portfolio",
          route: {
            name: "portfolio",
            params: {
              userID: this.$store.state.Profile.profileData.id,
            },
          },
          target: '_blank',
          icon: "BookIcon",
        },
        {
          title: "Add templates",
          route: { name: "templates" },
          icon: "EditIcon",
        },
        {
          title: "All liclenses",
          route: { name: "all-licenses" },
          icon: "EditIcon",
        },
      ];
    },
  },

  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
