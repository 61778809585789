<template>
  <li
    v-if="couldSeeAdminNavLink(item)"
    class="nav-item"
    @click="itemAction(item)"
    :class="{
      disabled: disabledLink(item),
      active: isActive
    }"
  >   
    <router-link :to="item.route" class="d-flex align-items-center" :target="item.target">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </router-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl"
import {  BBadge } from "bootstrap-vue"
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import useVerticalNavMenuLink from "./useVerticalNavMenuLink"
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink"
import { logoutUser } from "@/auth/utils"
 
export default {
  components: { 
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    profileData() {
      return this.$store.state.Profile.profileData
    },
  },
  methods: {
    disabledLink(item) {
      let projectId = this.$store.state.Project.project.id
      switch (item.title) {
        case "Team":
          return projectId ? false : true
      }
    },
    couldSeeAdminNavLink(item) {
      if (['templates', 'all-licenses'].includes(item.route.name)) {
        return this.$store.state.Profile.isMeAdmin ? true : false
      }
      return true
    },
    itemAction(item) { 
      if (item.title == "Logout") {
        logoutUser()
        this.$router.push({ name: "auth-login" })
      }  
    },
  },
  setup(props) { 
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
}
</script>
