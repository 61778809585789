<template>
  <li
    v-if="canViewVerticalNavMenuGroup(item)"
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: disabledLink(item),
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <template v-for="(child, index) in item.children">
        <vertical-nav-menu-link
          :key="index"
          v-if="!child.header"
          :item="child"
        ></vertical-nav-menu-link>
      </template>
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from "bootstrap-vue"
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils"
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import { useUtils as useAclUtils } from "@core/libs/acl"
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue"

// Composition Function
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup"

export default {
  name: "VerticalNavMenuGroup",
  components: { 
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getEditor(projectID) {
      if (projectID) {
        this.$store
        .dispatch("Editor/getProjectEditors", projectID)
        .then((response) => {
          let selectedDevelopmentEditor = response.data.find(
            (x) => x.development === true
          );
          this.$store.commit("Editor/SET_EDITOR", selectedDevelopmentEditor)
        })
        .catch((err) => {
          errorMessage(this.$toast, err);
        });
      }
    },
    disabledLink(item) {
      let projectId = this.$store.state.Project.project.id
      switch (item.title) {
        case "Editor":
          return projectId &&
           this.$store.state.Project.project.project_type !== 'external'
            ? false
            : true
        case "Project Setting":
          return projectId ? false : true
        case "Analytics":
          return projectId ? false : true
      }
    },
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item)

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    }
  },
}
</script>

<style>
</style>
