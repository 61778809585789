<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" style="align-items: center;">
      <li>
        <b-img
            style="max-width: 55px; margin-left: -10px;"
            v-if="$store.state.appConfig.layout.skin !== 'dark'"
            :src="appLogoImage3"
            alt="logo"
        />
        <b-img
            style="max-width: 55px; margin-left: -10px;"
            v-else
            :src="appLogoImage4"
            alt="logo"
        />
      </li>
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <h2 style="margin-bottom: 0" v-if="currentProject && $store.state.app.windowWidth > 992">Project name: {{ currentProject.name }}</h2>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-lg-block" />
      <notification-dropdown />
      <locale />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import UserDropdown from './components/UserDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import DarkToggler from './components/DarkToggler.vue'

export default {
  components: {
    DarkToggler,
    BLink,
    BImg,

    // Navbar Components
    BNavbarNav,
    Locale,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentProject() {
      return this.$store.state.Project.project
    }
  },
  data: () => ({
    appLogoImage3: require('@/assets/images/logo/New_Project_2.png'),
    appLogoImage4: require('@/assets/images/logo/New_Project_4.png'),
  })
}
</script>
<style lang="scss">
  .alert-wrapper {

  }
</style>
