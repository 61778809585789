<template>
  <div class="customizer d-none d-md-block" :class="{'open': isCustomizerOpen}" v-if="!$route.path.startsWith('/project')">
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="PlusIcon"
        size="15"
        class="spinner"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h2 class="text-uppercase mb-0">
          Development preview
        </h2>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <!-- Header -->
    <iframe v-if="iframeURL" allowfullscreen="true" :src="iframeURL + '?isHosting=true'"></iframe>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import useAppCustomizer from './useAppCustomizer'

export default {
  components: {
    // BSV
    BLink, 
  },
  data() {
    return {
      currentProject: {},
      iframeURL: null,
    }
  },
  methods: {
    setUpdating(val) {
      this.$store.commit('app/SET_IS_PROJECT_UPDATING', Boolean(val))
    }
  },
  watch: {
    isCustomizerOpen(val) {
      if (val) {
        this.currentProject = {}
        this.$store.dispatch('Project/loadProject', this.$store.state.Project.project.id)
          .then(res => {
            console.log(res)
            this.currentProject = res.data
            this.iframeURL = res.data.development_url
          })
      }
    },
    isUpdating(val) {
      if (val) this.iframeURL = null
      else this.iframeURL = this.currentProject.development_url
    },
  },
  computed: {
    isUpdating() {
      return this.$store.state.app.isProjectUpdating
    }
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,
    } = useAppCustomizer()

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

iframe {
  width: 100%;
  height: 600px;
  margin-top: 40px;
}

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
.customizer .customizer-toggle {
    background: #fd1b62;
}
</style>
