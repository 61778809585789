<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newNotificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ newNotificationCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      @scroll="handleScroll"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in paginatedNotifications" :key="notification.id">
        <div>
          <div
            v-if="!notification.is_read"
            class="unread-note"
          >
          </div>
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :src="getUserImg(notification.request_user)"
                :text="''"
                :variant="''"
              />
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder float-left">
                {{ notification.message }}
              </span>
              <span class="float-right">{{ notification.ago }}</span>
            </p>
          </b-media>
        </div>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :class="{ loading: loading }"
        block
        @click="readAll()"
        >Read all notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
} from "bootstrap-vue"
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton, 
  },
  directives: {
    Ripple,
  },
  computed: {
    userImages() {
      return this.$store.state.Notification.all.user_pictures
    },
    notificationData() {
      const t =
        this.$store.state.Notification.all &&
        this.$store.state.Notification.all.notifications
      if (t) {
        t.forEach(e => {
          e.ago = this.msToHMS(e.time_ago)
        })
      }
      return t
    },
    paginatedNotifications() {
      return this.notificationData.slice(0, this.notificationsSize)
    },
    newNotificationCount() {
      let count = 0
      const notification = this.$store.state.Notification.all.notifications
      if (notification != undefined) {
        this.$store.state.Notification.all.notifications.forEach(element => {
          if (!element.is_read) {
            count++
          }
        })
      }

      return count
    },
  },
  data() {
    return {
      loading: false,
      notificationsSize: 10,
    }
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: "Congratulation Sam 🎉",
        avatar: require("@/assets/images/avatars/6-small.png"),
        subtitle: "Won the monthly best seller badge",
        type: "light-success",
      },
      {
        title: "New message received",
        avatar: require("@/assets/images/avatars/9-small.png"),
        subtitle: "You have 10 unread messages",
        type: "light-info",
      },
      {
        title: "Revised Order 👋",
        avatar: "MD",
        subtitle: "MD Inc. order updated",
        type: "light-danger",
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: "Server down",
        subtitle: "USA Server is down due to hight CPU usage",
        type: "light-danger",
        icon: "XIcon",
      },
      {
        title: "Sales report generated",
        subtitle: "Last month sales report generated",
        type: "light-success",
        icon: "CheckIcon",
      },
      {
        title: "High memory usage",
        subtitle: "BLR Server using high memory",
        type: "light-warning",
        icon: "AlertTriangleIcon",
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  mounted() {
    this.$store.dispatch("Notification/GET_NOTIFICATION")
  },
  methods: {
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.notificationsSize += 10
      }
    },
    readAll() {
      this.loading = true
      this.$store
        .dispatch("Notification/READ_ALL_NOTIFICATION")
        .then(() => {
          this.loading = false
          this.$store.dispatch("Notification/GET_NOTIFICATION")
        })
        .catch(() => {
          this.loading = false
        })
    },
    getUserImg(id) {
      return this.userImages[id]
    },
    msToHMS(minutes) {
      let hours = parseInt(minutes / 60) // 60 minutes in 1 hour
      let day = parseInt(hours / 24)
      let month = parseInt(day / 30)
      let year = parseInt(month / 12)

      if (year > 0) {
        return `${year} years`
      }
      if (month > 0) {
        return `${month} months`
      }
      if (day > 0) {
        return `${day} days`
      }
      if (hours > 0) {
        return `${hours} hours`
      }
      if (minutes > 0) {
        return `${minutes} minutes`
      }
      return 'Just Now'
    },
  },
}
</script>

<style>
.loading {
  pointer-events: none;
  opacity: 0.5;
}
.unread-note {
  position: absolute;
  background-color: #ea5455;
  border-radius: 90%;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-top: 5px;
}
</style>
